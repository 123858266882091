import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vmodal from 'vue-js-modal';
import '@/scss/_app.scss';

Vue.config.productionTip = false;

Vue.use(vmodal, { dialog: true });

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
